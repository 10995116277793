/* You can add global styles to this file, and also import other style files */

kendo-grid .graph-column-header{
  padding: 2px 1px !important;
  text-align: center;
  justify-content: center;
}

.graph-grid .k-grid-table{
  height: 100%;
}

.horizontal-form-field {
  display: flex !important;
  flex-direction: row;
}
.horizontal-form-field > kendo-label {
  margin-right: 5px;
}
